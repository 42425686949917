import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/DateInput/DateInput.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import WebComponent from '../../../WebComponent.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall.js'
import GeschaeftsvorfallNavigation from '../Navigation/GeschaeftsvorfallNavigation.js'
import GesuchService from '../../../services/GesuchService.js'
import DokumentService from '../../../services/DokumentService.js'
import VzavgFileInputService from '../../../services/VzavgFileInputService.js'
import DocumentDokIdUpload from '../../../components/DocumentUpload/DocumentDokIdUpload.js'
import StammdatenService from '../../../services/StammdatenService.js'
import ExportService from '../../../services/ExportService'


export default class Entscheid extends WebComponent {

  static SAVE_FILENAME_ID = 'save-filename'

  static get EVENT_KEYS() {
    return {
      UPLOAD_COMPLETE: 'entscheid-dokument-upload-complete'
    }
  }

  constructor() {
    super()
    this.gesuchService = new GesuchService()
    this.currentVersion = 1
  }

  get css() {
    return css`

      .flex {
        display: flex;
        flex-wrap: wrap;
        gap: 2em;
        min-width: 10em;
      }

      .flex > * {
        flex: 1;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
      }

      .buttons {
        margin-top: 3em;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 0.5em;
      }

      .save-dialog-input {
        width: calc(100% - 40px);
        display: inline-block;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Entscheid/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()
    this.backendData = {}
    this.dokIdsKanton = ['BE01', 'BE03', 'VE01', 'VE03', 'VE05', 'RE01']
    this.dokIdsSeco = ['BE02', 'BE04', 'VE02', 'VE04', 'VE06', 'RE02']
    this.gesuchId = getNavigationId()

    this._refresh = () => this._refreshDocument()
    document.addEventListener(DocumentDokIdUpload.EVENT_KEYS.UPDATED, this._refresh)

    this.exportService = new ExportService(`gesuch/${this.gesuchId}/export`)

    const stammdatenService = new StammdatenService()

    const dokumentIdStammdaten = await stammdatenService.getDokumentId()
    this.dokumentIdsEntscheidKanton = dokumentIdStammdaten
      .filter(d => this.dokIdsKanton.includes(d.code)).map(d => ({name: d.bezeichnung, value: d.code}))
    this.dokumentIdsEntscheidSeco = dokumentIdStammdaten
      .filter(d => this.dokIdsSeco.includes(d.code)).map(d => ({name: d.bezeichnung, value: d.code}))
    const entscheidGesuch = await stammdatenService.getEntscheidGesuch()
    this.entscheidGesuch = entscheidGesuch.map(eg => ({name: eg.bezeichnung, value: eg.code}))

    // cretae or get dokument for the betrieb and get dokumente of kategorie 'entscheid'
    this.gesuchService.createOrGetDokument(this.gesuchId + '/dokument/entscheid/detail')
      .then(dokumentId => {
        this.dokumentId = dokumentId
        this.dokumentService = new DokumentService(this.gesuchId, 'entscheid/file')
        this.dokumentService.validationConfig().then(validationConfig => {
          this.validationConfig = validationConfig;
          this.url = this.dokumentService.backendUrl;
          this.vzavgFileInputService = new VzavgFileInputService(null, '#dokument-override-modal-entscheid')
          this._getEntscheidAndRender()
        })
      })
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener(DocumentDokIdUpload.EVENT_KEYS.UPDATED, this._refresh)
  }

  getTemplate() {
    return html`
      <isceco-form id="entscheid-form">
        <div slot="form-elements">
          <div class="flex-column">
            ${this._renderTitle()}
            <div class="flex">
              <div class="flex-column">
                ${this._renderBewilligungsTyp()}
                ${this._renderEntscheidGesuch()}
                ${this._renderEmpfehlungForFL()}
                ${!this._isBewilligungspflichtig() ? this._renderBestaetigungsdatum() : ''}
                ${this._isBewilligungspflichtig() && this._isAv() ? this._renderAvErstbewilligungsdatum() : html``}
                ${this._isBewilligungspflichtig() && this._isAv() ? this._renderAvAenderungsdatum() : html``}
                ${this._isBewilligungspflichtig() && this._isPv() ? this._renderPvErstbewilligungsdatum() : html``}
                ${this._isBewilligungspflichtig() && this._isPv() ? this._renderPvAenderungsdatum() : html``}
                ${this._renderEntscheidungbegruendung()}
                ${this._renderSecoSonderPreis()}
                ${this._renderRechnung()}
              </div>
              <div class="flex-column">
                ${this._renderDatenHerunterladen()}
                ${this._renderBewiUrkundeTitle()}
                ${this._renderDokumentUpload()}
              </div>
            </div>
            ${this._renderDialogs()}
          </div>
        </div>
      </isceco-form>
    `
  }

  _isBewilligungspflichtig() {
    return this.backendData.bewilligungspflicht === 'yes'
  }

  _isAv() {
    return (this.backendData.isKantonProcess && this.backendData.arbeitsvermittlung !== 'no') ||
      (!this.backendData.isKantonProcess && this._isInternational(this.backendData.arbeitsvermittlung))
  }

  _isPv() {
    return (this.backendData.isKantonProcess && this.backendData.personalVerleih !== 'no') ||
      (!this.backendData.isKantonProcess && this._isInternational(this.backendData.personalVerleih))
  }

  _isInternational(type) {
    return type === 'yes_international' || type === 'yes_fl'
  }

  _renderTitle() {
    return html`
      <isceco-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('geschaeftsvorfall.entscheid.title')}">
        <nav class="title-navigation">
          <div class="buttons">
            <isceco-button
              id="entscheid-speichern"
              variant="primary"
              icon="save"
              @click="${_ => this.save()}"
              ?disabled="${this._notHasWriteAccessOrIsClosed()}"
            ></isceco-button>
            <isceco-button
              id="buttonAbschluss"
              variant="primary"
              text="${this.i18n.translate('geschaeftsvorfall.entscheid.abschluss')}"
              @click="${_ => this.save(true)}"
              ?disabled="${this._isAbschlussDisabled() || this._notHasWriteAccessOrIsClosed() || !this.backendData.id}"
            ></isceco-button>
          </div>
        </nav>
      </isceco-title>
    `
  }

  _renderBewilligungsTyp() {
    return html`
      <isceco-text-input
        id="bewilligungstyp"
        name="bewilligungstyp"
        value="${this.backendData.bewilligungstyp}"
        label="${this.i18n.translate('geschaeftsvorfall.entscheid.bewilligungstyp')}"
        ?readonly="${true}"
      ></isceco-text-input>
    `
  }

  _renderEntscheidGesuch() {
    return html`
      <isceco-dropdown
        id="entscheidGesuch"
        name="entscheidGesuch"
        label="${this.i18n.translate('geschaeftsvorfall.entscheid.gesuch')} *"
        value="${this.backendData.entscheidGesuch}"
        .items="${this.entscheidGesuch}"
        @change="${e => this._updateEntscheidGesuchValue(e)}"
        required
        ?readonly="${this._notHasWriteAccessOrIsClosed()}"
        placeholder="${this.i18n.translate('bitte.auswaehlen')}"
      ></isceco-dropdown>
    `
  }

  _renderBestaetigungsdatum() {
    return html`
      <isceco-date-input
        id="bestaetigungsdatum"
        name="bestaetigungsdatum"
        value="${this.backendData.bestaetigungsdatum}"
        label="${this.i18n.translate('geschaeftsvorfall.entscheid.bestaetigungsdatum')} *"
        @change="${e => this._updateValue(e)}"
        ?readonly="${this._notHasWriteAccessOrIsClosed()}"
        required
      ></isceco-date-input>
    `
  }

  _renderAvErstbewilligungsdatum() {
    return html`
      <isceco-date-input
        id="avErstbewilligungsdatum"
        name="avErstbewilligungsdatum"
        value="${this.backendData.avErstbewilligungsdatum}"
        label="${this.i18n.translate('geschaeftsvorfall.entscheid.avErstbewilligungsdatum')} *"
        @change="${e => this._updateValue(e, true)}"
        required
        ?readonly="${this._notHasWriteAccessOrIsClosed()}"
      ></isceco-date-input>
    `
  }

  _renderAvAenderungsdatum() {
    const label = this.i18n.translate('geschaeftsvorfall.entscheid.avAenderungsdatum') + (this.backendData.gesuchsart === 'aenderungsgesuch' ? ' *' : '')
    return !this.backendData.isKantonProcess || this.backendData.gesuchsart === 'aenderungsgesuch' ? html`
      <isceco-date-input
        id="avAenderungsdatum"
        name="avAenderungsdatum"
        value="${this.backendData.avAenderungsdatum}"
        label="${label}"
        @change="${e => this._updateValue(e, true)}"
        ?required="${this.backendData.gesuchsart === 'aenderungsgesuch'}"
        ?readonly="${this._notHasWriteAccessOrIsClosed()}"
      ></isceco-date-input>
    ` : html``
  }

  _renderPvErstbewilligungsdatum() {
    return html`
      <isceco-date-input
        id="pvErstbewilligungsdatum"
        name="pvErstbewilligungsdatum"
        value="${this.backendData.pvErstbewilligungsdatum}"
        label="${this.i18n.translate('geschaeftsvorfall.entscheid.pvErstbewilligungsdatum')} *"
        @change="${e => this._updateValue(e, true)}"
        required
        ?readonly="${this._notHasWriteAccessOrIsClosed()}"
      ></isceco-date-input>
    `
  }

  _renderPvAenderungsdatum() {
    const label = this.i18n.translate('geschaeftsvorfall.entscheid.pvAenderungsdatum') + (this.backendData.gesuchsart === 'aenderungsgesuch' ? ' *' : '')
    return !this.backendData.isKantonProcess || this.backendData.gesuchsart === 'aenderungsgesuch' ? html`
      <isceco-date-input
        id="pvAenderungsdatum"
        name="pvAenderungsdatum"
        value="${this.backendData.pvAenderungsdatum}"
        label="${label}"
        @change="${e => this._updateValue(e, true)}"
        ?required="${this.backendData.gesuchsart === 'aenderungsgesuch'}"
        ?readonly="${this._notHasWriteAccessOrIsClosed()}"
      ></isceco-date-input>
    ` : html``
  }

  _renderEntscheidungbegruendung() {
    return html`
      <div>
        <div style="font-weight: bold; margin-bottom: 4px">
          ${this.i18n.translate('geschaeftsvorfall.entscheid.entscheidungsbegruendungen')}
        </div>
        <isceco-button
          id="notizErfassen"
          variant="secondary"
          text="${this.i18n.translate('geschaeftsvorfall.entscheid.notizErfassen')}"
          @click="${_ => this._openNewNotiz()}"
          ?disabled="${this._notHasWriteAccessOrIsClosed()}"
        ></isceco-button>
      </div>
    `
  }

  _renderEmpfehlungForFL() {
    return !this.backendData.isKantonProcess && (this.backendData.arbeitsvermittlung === 'yes_fl' || this.backendData.personalVerleih === 'yes_fl') ? html`
      <isceco-radio-group
        id="empfehlungForFl"
        name="empfehlungForFl"
        value="${this.backendData.empfehlungForFl}"
        label="${this.i18n.translate('geschaeftsvorfall.entscheid.empfehlungForFl')}"
        .items="${[
          {value: true, name: this.i18n.translate('yes')},
          {value: false, name: this.i18n.translate('no')}
        ]}"
        @change="${e => this._updateValue(e)}"
        ?readonly="${this._notHasWriteAccessOrIsClosed()}"
        required
      ></isceco-radio-group>
    ` : html``
  }

  _renderSecoSonderPreis() {
    return this.backendData.isKantonProcess ? html`` : html`
      <isceco-dropdown
        id="secoSonderPreis"
        name="secoSonderPreis"
        label="${this.i18n.translate('geschaeftsvorfall.entscheid.secoSonderPreis')}"
        value="${this.backendData.secoSonderPreis}"
        .items="${[
          {value: 'yes', name: this.i18n.translate('yes')},
          {value: 'no', name: this.i18n.translate('no')}
        ]}"
        @change="${e => this._updateValue(e)}"
        required
        ?readonly="${this._notHasWriteAccessOrIsClosed()}"
      ></isceco-dropdown>
    `
  }

  _renderRechnung() {
    return this.backendData.isKantonProcess ? html`` : html`
      <isceco-button
        id="rechnung"
        variant="secondary"
        text="${this.i18n.translate('geschaeftsvorfall.entscheid.rechnung')}"
        @click="${_ => this._rechnung()}"
        ?disabled="${this._notHasWriteAccessOrIsClosed()}"
      ></isceco-button>
    `
  }

  _renderBewiUrkundeTitle() {
    return html`
      <isceco-title
        id="bewiUrkunde-title"
        size="medium"
        text="${this.i18n.translate('geschaeftsvorfall.entscheid.bewilligungsurkunde')}">
      </isceco-title>
    `
  }

  _renderDokumentUpload() {
    const relevantDokumentIds = this.backendData.isKantonProcess ? this.dokumentIdsEntscheidKanton : this.dokumentIdsEntscheidSeco
    return html`
      <div id="dokumenteContainer">
        <vzavg-document-dokid-upload
          fileInputId="entscheid-dokument-upload"
          .readonly="${false}"
          gesuchId="${this.gesuchId}"
          dokumentId="${this.dokumentId}"
          kategorie="entscheid"
          .fileInputService="${this.vzavgFileInputService}"
          .dokIdStammdaten="${relevantDokumentIds}"
          .dokumentItems="${this._getDokumentItems(relevantDokumentIds)}"
          .i18n="${this.i18n}"
          .backendData="${this.dokumente}"
          .currentVersion="${this.currentVersion}">
        </vzavg-document-dokid-upload>
      </div>

      <isceco-dialog id="dokument-override-modal-entscheid"
                     hidden
                     header="${this.i18n.translate('geschaeftsvorfall.entscheid.upload.popup.title')}"
                     description="${this.i18n.translate('geschaeftsvorfall.entscheid.upload.popup.text')}"
                     @submit="${e => e.target.setAttribute('hidden', '')}">

        <div style="display: flex; column-gap: 10px; margin-top: 20px;">
          <isceco-button id="btnAbbrechen" size="small" variant="primary"
                         text="${this.i18n.translate('geschaeftsvorfall.entscheid.upload.popup.abbrechen')}"
                         @click="${e => this._handleDokumentUploadModalOption(e)}">
          </isceco-button>
          <isceco-button id="btnLoeschen" size="small" variant="primary"
                         text="${this.i18n.translate('geschaeftsvorfall.entscheid.upload.popup.loeschen')}"
                         @click="${e => this._handleDokumentUploadModalOption(e)}">
          </isceco-button>
          <isceco-button id="btnNotiz" size="small" variant="primary" variant="primary"
                         text="${this.i18n.translate('geschaeftsvorfall.entscheid.upload.popup.alsnotiz')}"
                         @click="${e => this._handleDokumentUploadModalOption(e)}">
          </isceco-button>
        </div>
      </isceco-dialog>
    `
  }

  _handleDokumentUploadModalOption(e) {
    const modalDialog = document.querySelector('#dokument-override-modal-entscheid')
    modalDialog.setAttribute('hidden', '')
    if (e.target.id === 'btnAbbrechen') {
      return
    }

    modalDialog.fileUploadCallBackObject.dialogCallBackUpload(e.target.id === 'btnNotiz').then(_ => {
      send(Entscheid.EVENT_KEYS.UPLOAD_COMPLETE)
      this._getEntscheidAndRender()
    })
  }

  _getDokumentItems(dokumentItemsStammdaten) {
    const ruleItemsGenerell = this.dokumente.ruleItems ? this.dokumente.ruleItems.filter(ri =>
      this.backendData.isKantonProcess ? this.dokIdsKanton.includes(ri.value) : this.dokIdsSeco.includes(ri.value)) : []
    const notMandatoryItems = dokumentItemsStammdaten.filter(dig =>
      ruleItemsGenerell.find(ruleItem => ruleItem.value === dig.value) === undefined
    )
    notMandatoryItems.forEach(item => ruleItemsGenerell.push({
      'value': item.value,
      'name': item.name,
      'mandatory': false
    }))
    return ruleItemsGenerell.sort((i1, i2) => i1.value.localeCompare(i2.value))
  }

  _renderDatenHerunterladen() {
    return html`
      <isceco-button
        style="margin-top: 23px"
        id="datenHerunterladen"
        icon="download"
        text="${this.i18n.translate('geschaeftsvorfall.entscheid.datenHerunterladen')}"
        @click="${() => this._openCvsExportDialog()}"
      ></isceco-button>
    `
  }

  _renderDialogs() {
    return html`
      <isceco-dialog
        id="changeBewilligungspflichtDialog"
        hidden
        header="${this.i18n.translate('geschaeftsvorfall.entscheid.dialog.change.bewilligungspflicht.header')}"
        description="${this.i18n.translate('geschaeftsvorfall.entscheid.dialog.change.bewilligungspflicht.desc')}"
        confirm-button="${this.i18n.translate('confirm')}"
        Cancel-button="${this.i18n.translate('cancel')}"
        @submit="${e => this._handleChangeBewilligungspflichtDialogSumbit(e)}"
      ></isceco-dialog>
      <isceco-dialog
        id="uploadDocumentDialog"
        hidden
        header="${this.i18n.translate('geschaeftsvorfall.entscheid.dialog.upload.decision.header')}"
        description="${this.i18n.translate('geschaeftsvorfall.entscheid.dialog.upload.decision.desc')}"
        confirm-button="${this.i18n.translate('confirm')}"
        @submit="${e => this._handleUploadDecisionDialogSumbit(e)}"
      ></isceco-dialog>
      <isceco-dialog
        id="enterNoteDialog"
        hidden
        header="${this.i18n.translate('geschaeftsvorfall.entscheid.dialog.note.header')}"
        description="${this.i18n.translate('geschaeftsvorfall.entscheid.dialog.note.desc')}"
        confirm-button="${this.i18n.translate('confirm')}"
        @submit="${e => this._handleEnterNoteDialogSumbit(e)}"
      ></isceco-dialog>
      <isceco-dialog
        id="cvs-export-dialog"
        hidden
        header="${this.i18n.translate('suchen.result.export.header')}"
        description="${this.i18n.translate('suchen.result.export.description')}"
        confirm-button="${this.i18n.translate('suchen.result.export.confirm')}"
        Cancel-button="${this.i18n.translate('suchen.result.export.cancel')}"
        @submit="${e => this._exportCsv(e)}"
      >
        <isceco-text-input
          id="${Entscheid.SAVE_FILENAME_ID}"
          class="save-dialog-input"
          maxlength="50"
        ></isceco-text-input>
        .csv
      </isceco-dialog>
    `
  }

  _getEntscheidAndRender() {
    this.gesuchService.read(this.gesuchId, `entscheid`)
      .then(res => {
        this.backendData = res
        this.backendData.bewilligungstyp = this._buildBewilligungsTyp(res);
        this.backendData.bestaetigungsdatum = this.backendData.bestaetigungsdatum || this.backendData.bewilligungspflicht === 'yes' ? this.backendData.bestaetigungsdatum : this._now()
        this.backendData.ausstellungsdatum = this.backendData.ausstellungsdatum ? this.backendData.ausstellungsdatum : this._now()
        this.backendData.isKantonProcess = AuthorizationFilter.hasKantonRole()
        this.backendData.isStatusReadyToClose = this.backendData.isStatusReadyToClose ? this.backendData.isStatusReadyToClose : false
        this.backendData.empfehlungForFl = this.backendData.empfehlungForFl === undefined ? true : this.backendData.empfehlungForFl
        this.backendData.entscheidGesuch = this.backendData.entscheidGesuch || this.backendData.bewilligungspflicht === 'yes' ? this.backendData.entscheidGesuch : 'befreien'
        return this._refreshDocument()
      })
      .catch(_ => {
        showAlert('', this.i18n.translate('geschaeftsvorfall.entscheid.load.error'))
        this.render()
      })
  }

  _refreshDocument() {
    return new DokumentService(this.gesuchId, 'entscheid/detail').list(this.dokumentId).then(dokumente => {
      this.dokumente = dokumente
      this.currentVersion = dokumente.version
      this.render()
    })
  }

  _openCvsExportDialog() {
    const dialog = document.querySelector('#cvs-export-dialog')
    let filename = 'GV_' + this.backendData.firmenbezeichnung.replaceAll(" ", "_")
    if (filename.length > 50) {
      filename = filename.substring(0, 50)
    }
    dialog.querySelector(`#${Entscheid.SAVE_FILENAME_ID}`).value = filename
    dialog.removeAttribute('hidden')
  }

  save(abschlussRequired = false) {
    const form = document.getElementById("entscheid-form")

    if (!form.validate()) {
      return Promise.resolve(false)
    }

    this.backendData.abschlussRequired = abschlussRequired
    this.backendData.gesuchId = this.gesuchId
    if (this.backendData.id) {
      return this.gesuchService.update(this.backendData, `${this.gesuchId}/entscheid`)
        .then(_ => {
          this._showSavedAlert()
          send(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED)
          return true
        }).then(_ => {
          if (this.backendData.abschlussRequired) {
            navigate({to: geschaeftsVorfallNavigationLinks.uebersicht.hash, id: this.gesuchId})
          } else {
            this._getEntscheidAndRender()
          }
        })
        .catch(_ => false)
    } else {
      return this.gesuchService.create(this.backendData, `${this.gesuchId}/entscheid`)
        .then(response => {
          const location = response.headers.get('Location')
          this.backendData.id = location.slice(location.lastIndexOf('/') + 1)
          this._showSavedAlert()
          navigate({to: geschaeftsVorfallNavigationLinks.entscheid.hash, id: `${this.gesuchId}/${this.backendData.id}`})
          send(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED)
          return true
        }).then(_ => {
          this._getEntscheidAndRender()
        })
        .catch(_ => false)
    }
  }

  _showSavedAlert() {
    const title = this.i18n.translate('geschaeftsvorfall.entscheid.save.success.title')
    const message = this.i18n.translate('geschaeftsvorfall.entscheid.save.success.message')
    showAlert(title, message, 'success')
  }

  _updateEntscheidGesuchValue(e) {
    const value = e.detail.value
    this._updateValue(e, true)

    switch (value) {
      case 'befreien':
        if (this.backendData.bewilligungspflicht === 'yes') {
          document.getElementById('changeBewilligungspflichtDialog').removeAttribute('hidden')
        }
        break
      case 'ablehnen':
        document.getElementById('uploadDocumentDialog').removeAttribute('hidden')
        break
      case 'zurueckziehen':
      case 'stilllegen':
        document.getElementById('enterNoteDialog').removeAttribute('hidden')
        break
      default:
        // do nothing for other cases
        break
    }

  }

  _updateValue(event, reload = false) {
    window.hasChanges = true;
    const key = event.target.id;
    if (this.backendData[key] !== event.detail.value) {
      this.backendData[key] = event.detail.value
    }

    if (reload) {
      this.reload();
    }
  }

  _openNewNotiz() {
    const newNotizUrl = this._buildNotizUrl()
    window.open(newNotizUrl, "_blank");
  }

  _buildNotizUrl() {
    const to = `#${geschaeftsVorfallNavigationLinks.notizEdit.hash}`
    const id = this.gesuchId
    const params = [
      ['kategorie', 'Bewilligung']
    ]
    return constructURL({to, id, params})
  }

  _rechnung() {
    // TODO
  }

  _exportCsv(e) {
    if (e.detail.confirmed) {
      const filename = toValidFilename(e.target.querySelector(`#${Entscheid.SAVE_FILENAME_ID}`).getValue())
      this.exportService.getExport(filename, window.location.hash.split('?').pop())
        .then(response => response.blob())
        .then(blob => download(`${filename}.csv`, blob));
    }
    e.target.setAttribute('hidden', '')
  }

  _buildBewilligungsTyp(res) {
    if (res.personalVerleih === 'no') {
      return this.i18n.translate('geschaeftsvorfall.entscheid.bewilligungstyp.arbeitsvermittlung.' + res.arbeitsvermittlung)
    }

    if (res.arbeitsvermittlung === 'no') {
      return this.i18n.translate('geschaeftsvorfall.entscheid.bewilligungstyp.personalverleih.' + res.personalVerleih)
    }

    return `${(this.i18n.translate('geschaeftsvorfall.entscheid.bewilligungstyp.personalverleih.' + res.personalVerleih))} + ${(this.i18n.translate('geschaeftsvorfall.entscheid.bewilligungstyp.arbeitsvermittlung.' + res.arbeitsvermittlung))}`
  }

  _now() {
    return new Date().toISOString().split('T')[0]
  }

  _handleChangeBewilligungspflichtDialogSumbit(e) {
    e.target.setAttribute("hidden", "")

    if (e.detail.confirmed) {
      this.backendData.entscheidGesuch = 'befreien'
      this.save()
    } else {
      document.getElementById('entscheidGesuch').value = this.backendData.entscheidGesuch
    }
  }

  _handleUploadDecisionDialogSumbit(e) {
    e.target.setAttribute("hidden", "")
  }

  _handleEnterNoteDialogSumbit(e) {
    e.target.setAttribute("hidden", "")
  }

  _isAbschlussDisabled() {
    return !this.backendData.isStatusReadyToClose || !this.backendData.entscheidGesuch || !this.backendData.ausstellungsdatum
  }

  _notHasWriteAccessOrIsClosed() {
    return !AuthorizationFilter.hasUserWriteAccess() || this._isClosedKanton() || this._isClosedSeco()
  }

  _isClosedKanton() {
    return AuthorizationFilter.hasKantonRole() && this.backendData.isClosed && this.backendData.isKanton
  }

  _isClosedSeco() {
    return AuthorizationFilter.hasSecoRole() && this.backendData.isClosed && !this.backendData.isKanton
  }
}
customElements.define('vzavg-frontend-geschaeftsvorfall-entscheid', Entscheid)
