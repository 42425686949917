import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'

export default class CockpitStatistikdatenKantonColumns {

  static columns() {
    return [
      this.KANTON_COLUMN(),
      this.TOTAL_COLUMN(),
      this.EXISTING_COLUMN(),
      this.MISSING_COLUMN(),
      this.PERCENTAGE_COLUMN()
    ]
  }

  static KANTON_COLUMN() {
    return new StringColumn({
      key: 'kanton',
      text: 'cockpit.statistikdaten.kanton',
      sortable: true,
      align: "center"
    })
  }

  static TOTAL_COLUMN() {
    return new StringColumn({
      key: 'total',
      text: 'cockpit.statistikdaten.total',
      sortable: true,
      align: "center"
    })
  }

  static EXISTING_COLUMN() {
    return new StringColumn({
      key: 'existing',
      text: 'cockpit.statistikdaten.existing',
      sortable: true,
      align: "center"
    })
  }

  static MISSING_COLUMN() {
    return new StringColumn({
      key: 'missing',
      text: 'cockpit.statistikdaten.missing',
      sortable: true,
      align: "center"
    })
  }

  static PERCENTAGE_COLUMN() {
    return new StringColumn({
      key: 'completionPercentage',
      text: 'cockpit.statistikdaten.percentage',
      sortable: true,
      align: "center"
    })
  }
}
