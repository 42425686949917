
import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/List/List.js'
import {geschaeftsVorfallNavigationLinks} from "../../Geschaeftsvorfall";
import TranslationElement from "../../../../common/TranslationElement";
import VzavgFormation from '../../../../common/VzavgFormation'

export default class StatistikendatenUebersicht extends TranslationElement {

  static get properties() {
    return {
      statistik: {type: Object},
      gesuchId: {type: String}
    }
  }

  render() {

    return html`
        <vzavg-link
          size="medium" text="${this.i18n.translate('title')}"
          resource="${geschaeftsVorfallNavigationLinks.statistikDaten.hash}/${this.gesuchId}"
        ></vzavg-link>
        ${this.statistik ? this._renderData() : this._renderNoData()}
    `
  }

  _renderNoData() {
    return html`
      <p>${this.i18n.translate('no.data')}</p>
    `
  }

  _renderData() {
    return html`
      <div class="stats-container">
        <p>${this.i18n.translate('date')} ${this.date}</p>
        ${this.statistik.hours ? html`<p>${this.i18n.translate('hours')} ${VzavgFormation.formatAmount(this.statistik.hours)}</p>` : html``}
      </div>
    `
  }

  get date() {
    const lastModificationDate = new Date(this.statistik.date);
    const day = lastModificationDate.getDate().toString().padStart(2, '0');
    const month = (lastModificationDate.getMonth() + 1).toString().padStart(2, '0');
    const year = lastModificationDate.getFullYear();
    return `${day}.${month}.${year}`;
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Uebersicht/StatistikendatenUebersicht/i18n.json'
  }
}
customElements.define('statistikdaten-uebersicht', StatistikendatenUebersicht)
