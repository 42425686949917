import '@isceco/widget-library2/index.js'
import '@isceco/widget-library2/core.css'
import './views/Header/Header.js'
import './views/Message/Message.js'
import './views/Navigation/Navigation.js'
import './views/Navigation/HorizontalNavigation.js'
import './views/MainView/MainView.js'
import './views/Footer/Footer.js'
import {Observable} from './common/Observable.js'

window.iscecoWidgetLibrary.iconCss = 'icon.min.css'
window.iscecoWidgetLibrary.languageKey = 'vzavg-frontend'
window.iscecoWidgetLibrary.supportedLanguages = ['de', 'fr', 'it']
window.hasChanges = false
window.betrieb$ = Observable(null);
window.snapshot$ = Observable(null);

if (!window.containsAlert) {
  window.containsAlert = () => isEmpty(window.alerts) ? false : window.alerts.length > 0
}

window.setNavigationParams = queryParamsString => {
  const baseUrl = location.origin + location.pathname + location.search
  const navigationHash = getNavigationHash() + getNavigationIds()
  const url = `${baseUrl}#${navigationHash}?${queryParamsString}`
  history.replaceState(null, null, url);
};

window.getNavigationIds = () => {
  const idArray = window.location.hash.split('?')[0].split('/');
  if (idArray.length <= 1) {
    return ''
  }
  idArray.shift()
  return '/' + idArray.join('/');
}

if (!window.isNoDataFound){
  window.isNoDataFound = response => response && response.status && response.status === 204
}

if (!window.isObjectEmpty){
  window.isObjectEmpty = obj => Object.keys(obj).length === 0
}

if (!window.isNewGesuch){
  window.isNewGesuch = () => getNavigationId() === '0'
}
