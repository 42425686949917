import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/Textarea/Textarea.js'
import WebComponent from '../../../WebComponent.js'
import '../../../components/DocumentViewer/DocumentViewer.js'
import GesuchService from '../../../services/GesuchService.js'
import DokumentService from '../../../services/DokumentService.js'
import VzavgFileInputService from '../../../services/VzavgFileInputService.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import DocumentViewer from '../../../components/DocumentViewer/DocumentViewer.js'


export default class DokumenteViewer extends WebComponent {

  constructor() {
    super()
    this.isClosedForKanton = false
    this.isClosedForSeco = false
    // hide horizontal menu in document viewer
    document.getElementById('navigation').hidden = true
    document.getElementById('maincontent').style = 'overflow: visible;'
    this.gesuchService = new GesuchService()
    this.vzavgFileInputService = new VzavgFileInputService()
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Dokumente/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()
    this.gesuchId = getNavigationId()
    this.selectedDokument = null
    this.hasChanges = false
    this.dokumentId = 0
    this.dokumentService = new DokumentService(this.gesuchId, 'dokumente/file')
    this.fileBlob = null

    this._refresh = () => {
      this._loadBackendDataAndRefresh()
    }

    Promise.all([
      this.gesuchService.read(this.gesuchId),
      this.gesuchService.read(this.gesuchId, 'betrieb'),
      this.gesuchService.createOrGetDokument(this.gesuchId+'/dokument/dokumente/detail')])
        .then(([gesuch, betrieb, dokumentId]) => {
          if (!isNoDataFound(betrieb)) {
            window.betrieb$.setValue(betrieb)
          } else {
            window.betrieb$.setValue(null)
          }
          this.dokumentId = dokumentId
          this.isClosedForKanton = gesuch.closedForKanton === undefined ? false : gesuch.closedForKanton
          this.isClosedForSeco = gesuch.closedForSeco === undefined ? false : gesuch.closedForSeco
          this._refresh()
    })
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.getElementById('navigation').hidden = false
  }

  _loadBackendDataAndRefresh() {
    this.gesuchService.read(this.gesuchId, `dokument/dokumente`)
      .then(backendData => {
        this.backendDokumente = backendData
        if(this.backendDokumente !== undefined) {
          this.dokumenteGenerell = this.backendDokumente.filter(d => d.dokIdCode.includes('GE')).reverse()
          this.dokumenteVermittlung = this.backendDokumente.filter(d => d.dokIdCode.includes('AV')).reverse()
          this.dokumenteVerleih = this.backendDokumente.filter(d => d.dokIdCode.includes('PV')).reverse()
          this.dokumenteVerantwortlicheP = this.backendDokumente.filter(d => d.dokIdCode.includes('VP')).reverse()
          this.dokumenteEntscheid = this.backendDokumente.filter(d => d.kategorie === 'entscheid').reverse()
          // get personIds (Verantwortliche Personnen)
          this.distinctVPIds = new Set(this.dokumenteVerantwortlicheP
            .map(dokument => dokument.personId))
          // groupBy personId
          this.dokumenteVerantwortlicheP = this.dokumenteVerantwortlicheP.reduce((group, dokument) => {
            const { personId } = dokument;
            group[personId] = group[personId] ?? [];
            group[personId].push(dokument);
            return group;
          }, {});

          // check if we have the default document and set it as default, in initial case
          this.selectedDokument = !this.selectedDokument ? this.dokumenteGenerell.find(dokument => dokument.dokIdCode === 'GE01') : this.selectedDokument
          if(this.selectedDokument) {
            this.dokumentService.getFile(this.selectedDokument.downloadPath, this.selectedDokument.fileName)
              .then(file => {
                this.fileBlob = file
                this.render()
              })
          } else {
            this.render()
          }
        } else {
          this.render()
        }
      })
  }

  getTemplate() {
    return this.backendDokumente !== undefined && this.backendDokumente.length > 0 ?
      this._renderContent() :
      html`${ this.i18n.translate('geschaeftsvorfall.dokument.viewer.empty.msg')}`
  }

  _renderContent() {
    return html`
        <vzavg-betrieb-info></vzavg-betrieb-info>
        <div style="display: flex; flex-wrap: wrap; margin-top: 15px;">
          <div style="display: flex; flex-direction: column; margin: 40px 20px 10px 0px;">

            <details ?hidden=${this.dokumenteGenerell.length === 0} ?open=${this.fileBlob} id="dokumenteGenerell" @click="${e => this._collapseOtherBereiche(e)}">
              <summary>
                ${this.dokumenteGenerell.length > 0 ? this.i18n.translate('geschaeftsvorfall.dokument.box.generell') : ''}
              </summary>
              <ul>
                ${this.dokumenteGenerell.map(dokument => this._renderDokument(dokument))}
              </ul>
            </details>
            <details ?hidden=${this.dokumenteVermittlung.length === 0} id="dokumenteVermittlung" @click="${e => this._collapseOtherBereiche(e)}">
              <summary>
                ${this.dokumenteVermittlung.length > 0 ? this.i18n.translate('geschaeftsvorfall.dokument.box.vermittlung') : ''}
              </summary>
              <ul>
                ${this.dokumenteVermittlung.map(dokument => this._renderDokument(dokument))}
              </ul>
            </details>
            <details ?hidden=${this.dokumenteVerleih.length === 0} id="dokumenteVerleih" @click="${e => this._collapseOtherBereiche(e)}">
              <summary>
                ${this.dokumenteVerleih.length > 0 ? this.i18n.translate('geschaeftsvorfall.dokument.box.verleih') : ''}
              </summary>
              <ul>
                ${this.dokumenteVerleih.map(dokument => this._renderDokument(dokument))}
              </ul>
            </details>

            ${this._getVerantwortlichePersonen()}

            <details ?hidden=${this.dokumenteEntscheid.length === 0} id="dokumenteEntscheid" @click="${e => this._collapseOtherBereiche(e)}">
              <summary>
                ${this.dokumenteEntscheid.length > 0 ? this.i18n.translate('geschaeftsvorfall.dokument.box.bewilligung.verfuegung') : ''}
              </summary>
              <ul>
                ${this.dokumenteEntscheid.map(dokument => this._renderDokument(dokument))}
              </ul>
            </details>
          </div>

          ${this.selectedDokument ? this._renderSelectedDokument() : html``}

        </div>
        <isceco-dialog
          id="viewer-save-dialog"
          hidden
          header="${this.i18n.translate('geschaeftsvorfall.dokument.viewer.leave.confirm.header')}"
          description="${this.i18n.translate('geschaeftsvorfall.dokument.viewer.leave.confirm.description')}"
          confirm-button="${this.i18n.translate('geschaeftsvorfall.dokument.viewer.dialog.confirm')}"
          cancel-button="${this.i18n.translate('geschaeftsvorfall.dokument.viewer.dialog.cancel')}"
          @submit="${e => this._closeConfirmDialog(e)}"
        ></isceco-dialog>
      `
  }

  _renderSelectedDokument() {
    return html`
      <vzavg-document-viewer style="flex-grow: 6;" .fileBlob="${this.fileBlob}" .callbackSave="${this._saveDocument}"></vzavg-document-viewer>
      <div style="flex-grow: 2">
        <isceco-card style="margin: 30px; 0px; 0px; 30px;" text="${this.i18n.translate('geschaeftsvorfall.dokument.viewer.metadata')}">
          <div id="geprueftDiv" style="margin-bottom: 15px;">
            <isceco-checkbox
              label="${this.i18n.translate('geschaeftsvorfall.dokument.viewer.file.geprueft')}"
              ?value="${this.selectedDokument.geprueft}"
              @change="${e => {
                this.hasChanges = true
                this.selectedDokument.geprueft = e.detail.value
                this.reload()
              }}"
              ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
            >
            </isceco-checkbox>
          </div>
          <isceco-textarea value="${this.selectedDokument.notiz}"
                           label="${this.i18n.translate('geschaeftsvorfall.dokument.viewer.notizen')}"
                           rows="15"
                           maxlength="1800"
                           @change="${e => {
                             this.hasChanges = true
                             this.selectedDokument.notiz = e.detail.value
                           }}"
                           ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}">
          </isceco-textarea>
          <isceco-button
            id="save"
            title="${this.i18n.translate('geschaeftsvorfall.dokument.viewer.button.speichern')}"
            icon="save"
            variant="primary"
            @click="${_ => this._save()}"
            ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-button>
        </isceco-card>
      </div>`
  }

  _collapseOtherBereiche(e) {
    if(e.currentTarget.attributes.getNamedItem('open') === null) {
      document.querySelectorAll('details')
        .forEach(element =>
          element.id !== e.currentTarget ? element.removeAttribute('open') : '')
    }
  }

  _showConfirmDialog() {
    const dialog = document.getElementById('viewer-save-dialog')
    dialog.hidden = false
  }

  _closeConfirmDialog(event) {
    event.target.hidden = true
    this.hasChanges = false
    if (event.detail.confirmed) {
      this._save()
    } else {
      this.selectedDokument =  { ...this.selectedDokumentInitial }
      this._loadBackendDataAndRefresh()
    }
  }

  _getVerantwortlichePersonen() {
    const options = []

    this.distinctVPIds.forEach(id => {
      options.push(html`
        <details ?hidden=${ this.dokumenteVerantwortlicheP[id].length === 0} id="dokumenteVerantwortlicheP${id}" @click="${e => this._collapseOtherBereiche(e)}">
          <summary>
            ${`${this.i18n.translate('geschaeftsvorfall.dokument.box.verantwperson')} (${this.dokumenteVerantwortlicheP[id][0].verantwortlichePerson})`}
          </summary>
          <ul>
            ${this.dokumenteVerantwortlicheP[id].map(dokument => this._renderDokument(dokument))}
          </ul>
        </details>
      `)
    })
    return options
  }

  _renderDokument(dokument) {
    return html`
        <li style="display: flex">
          <i style="color: ${dokument.geprueft ? 'green' : 'red'}" class="${dokument.geprueft ? 'check circle icon' : 'question circle icon'}"></i>
          <span>
            <isceco-button variant="tertiary"
                           style="${this.selectedDokument?.id === dokument.id ? 'border-left: 3px solid var(--isceco-color-red-500)' : ''}"
                           size="small"
                           text="${dokument.dokIdTranslation}"
                           @click="${_ => this._getAndShowFile(dokument)}">
            </isceco-button>
        </li>
    `
  }

  _getAndShowFile(dokument) {
    if(this.hasChanges) {
      this._showConfirmDialog()
    } else {
      this.selectedDokumentInitial = { ...dokument }
      this.selectedDokument = dokument
      this.dokumentService.getFile(dokument.downloadPath, dokument.fileName)
        .then(file => {
          this.fileBlob = file
          this.reload()
        })
    }
  }

  _save() {
    this.dokumentService.create(this.selectedDokument, this.selectedDokument.id +'/notiz')
      .then(_ => {
        this.hasChanges = false
        // trigger save document from pdf viewer to call our callback function '_saveDocument'
        send(DocumentViewer.EVENT_KEYS.UPDATE_DOCUMENT);
      })
  }

  _saveDocument = pdfData => {
    const file = new File([pdfData], this.selectedDokument.fileName, {type:'application/pdf'});
    this.vzavgFileInputService.setUrl(this.selectedDokument.downloadPath)
    this.vzavgFileInputService.upload(file)
      .then(_ => {
        showAlert(
          this.i18n.translate('geschaeftsvorfall.dokument.viewer.title.save'),
          this.i18n.translate('geschaeftsvorfall.dokument.viewer.message.save'),
          'success'
        )
        this._loadBackendDataAndRefresh()
      }).catch(() => {
        showAlert(
          this.i18n.translate('geschaeftsvorfall.dokument.viewer.title.save.error'),
          this.i18n.translate('geschaeftsvorfall.dokument.viewer.message.save.error'),
          'error'
        )
      })
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall-dokumenteviewer', DokumenteViewer)
