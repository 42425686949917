import {LitElement, html, css} from '@isceco/widget-library2/external/lit';

class PersonBetriebInfo extends LitElement {
  static get styles() {
    return css`
      .icon.exclamation {
        color: var(--isceco-color-yellow-500)
      }
    `
  }

  static get properties() {
    return {
      i18n: {type: Object},
      verantwortlichePersonSozVersNr: {type: String},
      searchUrl: {type: String}
    }
  }

  constructor() {
    super()
  }

  connectedCallback() {
    super.connectedCallback()
  }

  render() {
    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}'</style>
      <p><i class="exclamation triangle icon"></i> ${this.i18n?.translate('geschaeftsvorfall.verantwortliche.person.other.betrieb')} - ${this._renderLinks()}</p>
    `
  }

  _renderLinks() {
    return html`<a
                href="${this._getLink()}"
                target="_blank">${this.i18n?.translate('geschaeftsvorfall.verantwortliche.person.other.betrieb.search')}
                <i class="icon external alternate"></i></a> `
  }

  _getLink() {
    const baseUrl = location.origin + location.pathname + location.search
    return `${baseUrl}#${this.searchUrl}?suchbegriff=${this.verantwortlichePersonSozVersNr}`
  }
}

customElements.define('vzavg-person-betrieb-info', PersonBetriebInfo);
