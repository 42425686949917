import {css, html} from '@isceco/widget-library2/external/lit';
import WebComponent from '../../../WebComponent.js';
import StatistikService from '../../../services/StatistikService'
import '@isceco/widget-library2/basic-elements/Form/Form'
import GesuchService from '../../../services/GesuchService'
import '@isceco/widget-library2/basic-elements/Popup/Popup.js'
import {noYesInternationalFlOptions} from '../Gesuch/Gesuch'
import '@isceco/widget-library2/basic-elements/NumberInput/NumberInput.js'

const ABWEICHUNG_KEY = 'geschaeftsvorfall.statistikdaten.abweichung'
export default class StatistikdatenAktuell extends WebComponent {

  static AV_FORM_ID = 'av-form'
  static PV_FORM_ID = 'pv-form'
  static YEAR_INPUT_ID = 'year-input'

  get css() {
    return css`
      .wrapper {
        max-width: 850px;
      }

      .button-wrapper {
        display: flex;
        gap: 1em;
        justify-content: flex-end;
        margin-top: 2em;
      }

      .year-wrapper {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 2 / 4;
        font-weight: bold;
      }

      .betrieb-info {
        display: grid;
        grid-template-columns: max-content auto;
        gap: 0.5em 1em;
      }

      .column {
        display: flex;
        flex-direction: column;
        gap: 1em;
      }

      isceco-form {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / 5
      }

      .stats-sheet {
        display: grid;
        grid-template-columns: 3fr 1fr 2fr 2fr;
        gap: 1em;
        align-items: center;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/Statistikdaten/i18n.json'
  }

  get hasAV() {
    return !!this.gesuch.arbeitsVermittlung && this.gesuch.arbeitsVermittlung !== 'no'
  }

  get hasPV() {
    return !!this.gesuch.personalVerleih && this.gesuch.personalVerleih !== 'no'
  }

  get betriebsArt() {
    let betriebsArt = ''
    if (this.hasAV) {
      const arbeitsVermittlungOption = noYesInternationalFlOptions(this.i18n).find(av => av.value === this.gesuch.arbeitsVermittlung).name
      betriebsArt = `${this.i18n.translate("geschaeftsvorfall.statistikdaten.av")} (${arbeitsVermittlungOption})`
    }

    if (this.hasPV) {
      const personalverleihOption = noYesInternationalFlOptions(this.i18n).find(av => av.value === this.gesuch.personalVerleih).name;
      const plusStr = betriebsArt.length > 0 ? ' + ' : ''
      const personalverleih = `${plusStr} ${this.i18n.translate("geschaeftsvorfall.statistikdaten.pv")} (${personalverleihOption})`
      betriebsArt = betriebsArt.length ? `${betriebsArt} ${personalverleih}` : personalverleih;
    }

    if (!this.hasPV && !this.hasAV) {
      betriebsArt = this.i18n.translate('geschaeftsvorfall.gesuch.noAVorPV')
    }

    return betriebsArt
  }

  get isFreigebenDisabled() {
    let disabled = false;
    if (this.hasAV) {
      const fields = ['anzahlSchweizer', 'anzahlSchweizerinnen', 'anzahlAuslaender', 'anzahlAuslaenderinnen'];
      disabled = this.areRequiredFieldsUnset(fields, 'arbeitsvermittlung')
    }

    if (this.hasPV) {
      const fields = ['anzahlSchweizer', 'anzahlSchweizerinnen', 'anzahlAuslaender', 'anzahlAuslaenderinnen', 'geleisteteEinsatzstunden'];
      disabled = this.areRequiredFieldsUnset(fields, 'personalverleih')
    }

    return disabled
  }

  connectedCallback() {
    super.connectedCallback()
    this.statistikService = new StatistikService();
    this.gesuchService = new GesuchService()
    this.prevYearData = {}
    this.currentData = {}
    this.gesuch = null
    this.loadData()
  }

  loadData() {
    const betrieb = window.betrieb$.getValue()
    Promise.all([this.statistikService.list(betrieb.id), this.gesuchService.read(betrieb.gesuchId)])
      .then(([statistikData, gesuch]) => {
          const freigegebeneJahre = statistikData.filter(y => y.freigegeben);
          const nichtFreigegebeneJahre = statistikData.filter(y => !y.freigegeben);
          this.gesuch = gesuch

          this.prevYearData = freigegebeneJahre.length > 0 ? freigegebeneJahre[0] : {}
          if (nichtFreigegebeneJahre.length > 0) {
            this.currentData = nichtFreigegebeneJahre[0]
          } else {
            this.currentData = {
              year: null
            }
          }

          if (!this.currentData.arbeitsvermittlung) {
            this.currentData.arbeitsvermittlung = {}
          }

          if (!this.currentData.personalverleih) {
            this.currentData.personalverleih = {}
          }

          this._calculateAvTotalAndSetAbweichung()
          this._calculatePvTotalAndSetAbweichung()
          this.render();
        }
      )
  }

  getTemplate() {
    return html`
      <div class="wrapper">
        <h1>${this.i18n.translate('geschaeftsvorfall.statistikdaten.header')}</h1>
        <div class="betrieb-info">
          <span>${this.i18n.translate('geschaeftsvorfall.statistikdaten.email')}</span>
          <span>${window.betrieb$.getValue().email}</span>
          <span>${this.i18n.translate('geschaeftsvorfall.statistikdaten.betriebsart')}</span>
          <span>${this.betriebsArt}</span>
        </div>

        ${this.hasAV || this.hasPV ? html`
          <div class="stats-sheet" style="margin-top: 20px">
            <h2>${this.i18n.translate("geschaeftsvorfall.statistikdaten.berichtsjahr")}</h2>
            <h2 style="text-align: center;">${this.prevYearData.year}</h2>
            <isceco-number-input
              id="${StatistikdatenAktuell.YEAR_INPUT_ID}"
              required
              value="${this.currentData.year}"
              @keyup="${e => this._updateValueAndCheckRange(e)}">
            </isceco-number-input>
            <span></span>
          </div>
        ` : html``
        }

        ${this.hasAV ? this.renderAVStatistik() : html``}
        ${this.hasPV ? this.renderPVStatistik() : html``}
        ${this.hasAV || this.hasPV ? html`
          <div class="button-wrapper">
            <isceco-button text="${this.i18n.translate('geschaeftsvorfall.statistikdaten.speichern')}"
                           @click="${_ => this.save()}"></isceco-button>
            <isceco-button
              text="${this.i18n.translate('geschaeftsvorfall.statistikdaten.freigeben')}"
              ?disabled="${this.isFreigebenDisabled}"
              variant="primary"
              @click="${_ => this.freigeben()}"></isceco-button>
          </div>
        ` : html``
        }
      </div>
    `
  }

  renderAVStatistik() {
    return html`
      <h2>${this.i18n.translate("geschaeftsvorfall.statistikdaten.av")}</h2>
      <isceco-form id="${StatistikdatenAktuell.AV_FORM_ID}">
        <div slot="form-elements" class="column">
          <div class="stats-sheet">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlSchweizer')}</label>
            <label style="text-align: center">${this.prevYearData.arbeitsvermittlung?.anzahlSchweizer}</label>
            <isceco-number-input
              id="anzahlSchweizer"
              name="anzahlSchweizer"
              .value="${this.currentData.arbeitsvermittlung?.anzahlSchweizer?.toString() ?? ""}"
              maximum-fraction-digits="${0}"
              @change="${e => this.updateAvValue(e)}"
            ></isceco-number-input>
            <span></span>

            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlSchweizerinnen')}</label>
            <label style="text-align: center">${this.prevYearData.arbeitsvermittlung?.anzahlSchweizerinnen}</label>
            <isceco-number-input
              id="anzahlSchweizerinnen"
              name="anzahlSchweizerinnen"
              .value="${this.currentData.arbeitsvermittlung?.anzahlSchweizerinnen?.toString() ?? ''}"
              maximum-fraction-digits="${0}"
              @change="${e => this.updateAvValue(e)}"
            ></isceco-number-input>
            <span></span>

            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlAuslaender')}</label>
            <label style="text-align: center">${this.prevYearData.arbeitsvermittlung?.anzahlAuslaender}</label>
            <isceco-number-input
              id="anzahlAuslaender"
              name="anzahlAuslaender"
              .value="${this.currentData.arbeitsvermittlung?.anzahlAuslaender?.toString() ?? ''}"
              maximum-fraction-digits="${0}"
              @change="${e => this.updateAvValue(e)}"
            ></isceco-number-input>
            <span></span>

            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlAuslaenderinnen')}</label>
            <label style="text-align: center">${this.prevYearData.arbeitsvermittlung?.anzahlAuslaenderinnen}</label>
            <isceco-number-input
              id="anzahlAuslaenderinnen"
              name="anzahlAuslaenderinnen"
              .value="${this.currentData.arbeitsvermittlung?.anzahlAuslaenderinnen?.toString() ?? ''}"
              maximum-fraction-digits="${0}"
              @change="${e => this.updateAvValue(e)}"
            ></isceco-number-input>
            <span></span>

            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlVermittletePersonen')}</label>
            <label style="text-align: center">${this.prevYearData.arbeitsvermittlung?.total}</label>
            <isceco-number-input
              id="anzahlVermittletePersonen"
              name="anzahlVermittletePersonen"
              .value="${this.currentData.arbeitsvermittlung?.total?.toString() ?? ''}"
              maximum-fraction-digits="${0}"
              disabled
            ></isceco-number-input>
            <span>${this.avAbweichung}</span>
          </div>
          <isceco-checkbox
            name="onlinePlacement"
            label="${this.i18n.translate('geschaeftsvorfall.statistikdaten.av.online.placement')}"
            ?value="${this.currentData.arbeitsvermittlung?.onlinePlacement}"
          ></isceco-checkbox>
        </div>
      </isceco-form>
    `
  }

  renderPVStatistik() {
    return html`
      <h2>${this.i18n.translate("geschaeftsvorfall.statistikdaten.pv")}</h2>

      <isceco-form id="${StatistikdatenAktuell.PV_FORM_ID}">
        <div slot="form-elements" class="column">
          <div class="stats-sheet">
            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlSchweizer')}</label>
            <label style="text-align: center">${this.prevYearData.personalverleih?.anzahlSchweizer}</label>
            <isceco-number-input
              id="anzahlSchweizer"
              name="anzahlSchweizer"
              .value="${this.currentData.personalverleih?.anzahlSchweizer?.toString() ?? ""}"
              maximum-fraction-digits="${0}"
              @change="${e => this.updatePvValue(e)}"
            ></isceco-number-input>
            <span></span>

            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlSchweizerinnen')}</label>
            <label style="text-align: center">${this.prevYearData.personalverleih?.anzahlSchweizerinnen}</label>
            <isceco-number-input
              id="anzahlSchweizerinnen"
              name="anzahlSchweizerinnen"
              .value="${this.currentData.personalverleih?.anzahlSchweizerinnen?.toString() ?? ""}"
              maximum-fraction-digits="${0}"
              @change="${e => this.updatePvValue(e)}"
            ></isceco-number-input>
            <span></span>

            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlAuslaender')}</label>
            <label style="text-align: center">${this.prevYearData.personalverleih?.anzahlAuslaender}</label>
            <isceco-number-input
              id="anzahlAuslaender"
              name="anzahlAuslaender"
              .value="${this.currentData.personalverleih?.anzahlAuslaender?.toString() ?? ""}"
              maximum-fraction-digits="${0}"
              @change="${e => this.updatePvValue(e)}"
            ></isceco-number-input>
            <span></span>

            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlAuslaenderinnen')}</label>
            <label style="text-align: center">${this.prevYearData.personalverleih?.anzahlAuslaenderinnen}</label>
            <isceco-number-input
              id="anzahlAuslaenderinnen"
              name="anzahlAuslaenderinnen"
              .value="${this.currentData.personalverleih?.anzahlAuslaenderinnen?.toString() ?? ""}"
              maximum-fraction-digits="${0}"
              @change="${e => this.updatePvValue(e)}"
            ></isceco-number-input>
            <span></span>

            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.anzahlVerliehenePersonen')}</label>
            <label style="text-align: center">${this.prevYearData.personalverleih?.total}</label>
            <isceco-number-input
              id="anzahlVerliehenePersonen"
              name="anzahlVerliehenePersonen"
              .value="${this.currentData.personalverleih?.total?.toString() ?? ''}"
              maximum-fraction-digits="${0}"
              disabled
            ></isceco-number-input>
            <span>${this.pvAbweichung}</span>

            <label>${this.i18n.translate('geschaeftsvorfall.statistikdaten.geleisteteEinsatzstunden')}</label>
            <label style="text-align: center">${this.prevYearData.personalverleih?.geleisteteEinsatzstunden}</label>
            <isceco-number-input
              id="geleisteteEinsatzstunden"
              name="geleisteteEinsatzstunden"
              .value="${this.currentData.personalverleih?.geleisteteEinsatzstunden?.toString() ?? ''}"
              maximum-fraction-digits="${0}"
              @change="${e => this.updatePvValue(e)}"
              ?required="${this.geleisteteEinsatzstunden.isRequired}"
              ?disabled="${this.geleisteteEinsatzstunden.isDisabled}"
              .min="${this.geleisteteEinsatzstunden.min}"
            ></isceco-number-input>
            <span>${this.geleisteteEinsatzstunden.abweichung}</span>
          </div>
        </div>
      </isceco-form>
    `
  }

  save() {
    const currentYearElement = document.getElementById(StatistikdatenAktuell.YEAR_INPUT_ID)
    if(currentYearElement.validate()) {
      const values = this.collectValues();
      this.saveOrUpdate(values).then(() => {
        showAlert(
          this.i18n.translate('geschaeftsvorfall.statistikdaten.create.success.title'),
          this.i18n.translate('geschaeftsvorfall.statistikdaten.create.success.message'),
          'success'
        )
        this.loadData()
      })
    }
  }

  areRequiredFieldsUnset(fields, category) {
    if (!this.currentData[category]) {
      return true
    }
    return fields.some(f => this.currentData[category][f] === undefined || this.currentData[category][f] === null || this.currentData[category][f] === '')
  }

  _updateValueAndCheckRange(event) {
    const currentYear = new Date().getFullYear()
    if(this.prevYearData?.year) {
      event.target.min = Math.max(Number(this.prevYearData.year) + 1, currentYear - 1)
    } else {
      event.target.min = currentYear - 1
    }
    event.target.max = currentYear + 1

    event.target.patternErrorText = this.i18n.translate('geschaeftsvorfall.statistikdaten.tab.aktuell.year.validity.error.msg', [event.target.min, event.target.max])
    event.target.validate()
  }

  updateAvValue(event) {
    this.currentData.arbeitsvermittlung[event.target.id] = event.detail.value;
    this._calculateAvTotalAndSetAbweichung()
    this.render()
  }

  updatePvValue(event) {
    this.currentData.personalverleih[event.target.id] = event.detail.value;
    this._calculatePvTotalAndSetAbweichung()
    this.render()
  }

  _calculateAvTotalAndSetAbweichung() {
    this.avAbweichung = ''

    const av = this.currentData.arbeitsvermittlung
    if (!av) {
      return
    }

    av.total = Number(av.anzahlSchweizer ?? 0) + Number(av.anzahlSchweizerinnen ?? 0) + Number(av.anzahlAuslaender ?? 0) + Number(av.anzahlAuslaenderinnen ?? 0)

    const lastYearTotal = this.prevYearData.arbeitsvermittlung?.total ?? 0
    if (lastYearTotal !== 0) {
      const currentYearTotal = av.total
      const percent = Math.round((currentYearTotal - lastYearTotal) * 100 / lastYearTotal)
      this.avAbweichung = `${this.i18n.translate(ABWEICHUNG_KEY)}: ${percent}%`
    }
  }

  _calculatePvTotalAndSetAbweichung() {
    this.geleisteteEinsatzstunden = {}
    this.geleisteteEinsatzstunden.abweichung = ''
    this.geleisteteEinsatzstunden.isRequired = false
    this.geleisteteEinsatzstunden.isDisabled = true
    this.geleisteteEinsatzstunden.min = 0

    const pv = this.currentData.personalverleih
    if (!pv) {
      return
    }

    pv.total = Number(pv.anzahlSchweizer ?? 0) + Number(pv.anzahlSchweizerinnen ?? 0) + Number(pv.anzahlAuslaender ?? 0) + Number(pv.anzahlAuslaenderinnen ?? 0)

    const lastYearTotal = this.prevYearData.personalverleih?.total ?? 0
    if (lastYearTotal === 0) {
      this.pvAbweichung = ''
    } else {
      const currentYearTotal = pv.total
      const percent = Math.round((currentYearTotal - lastYearTotal) * 100 / lastYearTotal)
      this.pvAbweichung = `${this.i18n.translate(ABWEICHUNG_KEY)}: ${percent}%`
    }

    const lastYearGE = this.prevYearData.personalverleih?.geleisteteEinsatzstunden ?? 0
    const currentYearGE = pv.geleisteteEinsatzstunden
    if (lastYearGE === 0 || !currentYearGE) {
      this.geleisteteEinsatzstunden.abweichung = ''
    } else {
      const percent = Math.round((currentYearGE - lastYearGE) * 100 / lastYearGE)
      this.geleisteteEinsatzstunden.abweichung = `${this.i18n.translate(ABWEICHUNG_KEY)}: ${percent}%`
    }

    if (pv.total !== 0) {
      this.geleisteteEinsatzstunden.isRequired = true
      this.geleisteteEinsatzstunden.isDisabled = false
      this.geleisteteEinsatzstunden.min = 1
    } else {
      pv.geleisteteEinsatzstunden = 0
    }
  }

  freigeben() {
    const currentYearElement = document.getElementById(StatistikdatenAktuell.YEAR_INPUT_ID)
    if(currentYearElement.validate()) {
      const values = this.collectValues()
      values.freigegeben = true
      this.saveOrUpdate(values).then(() => {
        showAlert(
          this.i18n.translate('geschaeftsvorfall.statistikdaten.freigegeben.success.title'),
          this.i18n.translate('geschaeftsvorfall.statistikdaten.freigegeben.success.message'),
          'success'
        )
        this.currentData = {}
        this.loadData();
      })
    }
  }

  collectValues() {
    const values = {}
    if (this.hasAV) {
      values.arbeitsvermittlung = document.getElementById(StatistikdatenAktuell.AV_FORM_ID).getValues()
    }
    if (this.hasPV) {
      values.personalverleih = document.getElementById(StatistikdatenAktuell.PV_FORM_ID).getValues();
    }
    values.year = document.getElementById(StatistikdatenAktuell.YEAR_INPUT_ID).value;
    return values;
  }

  saveOrUpdate(values) {
    if (this.currentData.id) {
      return this.statistikService.update({...values, id: this.currentData.id}, window.betrieb$.getValue().id)
    } else {
      return this.statistikService.create(values, window.betrieb$.getValue().id)
    }
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall-statistikdaten-aktuell', StatistikdatenAktuell)
