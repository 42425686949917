import {css, html, LitElement} from '@isceco/widget-library2/external/lit';
import '@isceco/widget-library2/basic-elements/FileInput/FileInput.js'
import DokumentService from '../../services/DokumentService.js'
import VzavgFileInputService from '../../services/VzavgFileInputService.js'
import {geschaeftsVorfallNavigationLinks} from "../../views/Geschaeftsvorfall/Geschaeftsvorfall";
import {getFomanticStylesheet} from '@isceco/widget-library2/assets/fomantic-icons-styles.js'

export default class DocumentUpload extends LitElement {

  static get EVENT_KEYS() {
    return {
      UPLOAD_COMPLETE: 'document-upload-complete'
    }
  }

  static get properties() {
    return {
      gesuchId: { type: String },
      kategorie: { type: String },
      subKategorie: { type: String },
      objektId: { type: String },
      i18n: { type: Object },
      readonly: { type: Boolean }
    }
  }

  static get styles() {
    return css`
          .flexRowView {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
          }
          .dokumente-upload-button {
            margin-top: 0.5em;
          }
          .upload-notice {
            padding: 0.3em;
            background-color: var(--isceco-color-blue-300);
          }
          .document-label {
            font-weight: bold;
            color: var(--isceco-color-black-500);
          }
        `
  }

  constructor() {
    super()
    this.url = ''
    this.dokumente = []
    this.validationConfig = {};
    this.readonly = false
  }

  connectedCallback() {
    super.connectedCallback()

    this.dokumentService = new DokumentService(this.gesuchId, `${this.kategorie}/file`)
    this.dokumentService.validationConfig().then(validationConfig => {
      this.validationConfig = validationConfig;
      this.url = this.dokumentService.backendUrl;
      return this.fetchData();
    })

    if (this.kategorie === 'notizen') {
      this.listHash = geschaeftsVorfallNavigationLinks.notiz.hash
    } else if (this.kategorie === 'pendenzen') {
      this.listHash = geschaeftsVorfallNavigationLinks.pendenz.hash
    }

    getFomanticStylesheet()
      .then(stylesheet => {
        this.shadowRoot.adoptedStyleSheets = [...this.shadowRoot.adoptedStyleSheets, stylesheet]})
      .catch(error => {
        throw error
      })

  }

  fetchData() {
    return new DokumentService(this.gesuchId, `${this.kategorie}/${this.subKategorie}/${this.objektId}`).list().then(dokumente => {
      this.dokumente = dokumente;
      this.requestUpdate();
    })
  }

  render() {
    if (this.readonly){
      return html`
        ${this._renderReadOnlyDokumente()}
      `
    } else {
      return html`
        ${this._renderUploadButton()}
        ${this._renderDokumente()}
      `
    }
  }

  _renderDokumente() {
    if (this.dokumente.length === 0) {
      return html``;
    }

    const dokumente = this.dokumente.map((dokument, index) => html`
      <div class="flexRowView" style="margin: 20px 5px 5px 5px;">
        <div class="flexRowView" style="flex-grow: 2; gap: 5px;">
          <div>
            <isceco-button
              variant="tertiary"
              size="small"
              text="${this._getShortName(dokument.fileName)}"
              @click="${_ => this.dokumentService.download(dokument.downloadPath, dokument.fileName)}"
            ></isceco-button>
          </div>
        </div>
        <div class="flexRowView" style="gap: 20px;">
          <div>
            ${formatDate(dokument.angelegtAm)}
          </div>
          <div>
            <isceco-button
              id="${this._getDeleteBtnId(dokument.fileName)}"
              title=""
              variant="primary"
              size="small"
              icon="trash"
              @click="${_ => this._onDeleteClick(index)}"
            ></isceco-button>
          </div>
        </div>
      </div>
    `)

    return html`
            ${dokumente}
        `;
  }

  _renderReadOnlyDokumente() {
    if (this.dokumente.length === 0) {
      return html`
        <span class="document-label">${this.i18n.translate(`geschaeftsvorfall.${this.subKategorie}.detail.dokumente.hochladen`)}</span>
      `;
    }

    const dokumente = this.dokumente.map((dokument, index) => html`
      <div class="flexRowView" style="margin: 20px 5px 5px 5px;">
        <div class="flexRowView" style="flex-grow: 2; gap: 5px;">
          <div>
            <isceco-button
              variant="tertiary"
              size="small"
              text="${this._getShortName(dokument.fileName)}"
              @click="${_ => this.dokumentService.download(dokument.downloadPath, dokument.fileName)}"
            ></isceco-button>
          </div>
        </div>
        <div class="flexRowView" style="gap: 20px;">
          <div>
            ${formatDate(dokument.angelegtAm)}
          </div>
        </div>
      </div>
    `)

    return html`
      <span class="document-label">${this.i18n.translate(`geschaeftsvorfall.${this.subKategorie}.detail.dokumente.hochladen`)}</span>
            ${dokumente}
        `;
  }


  _renderUploadButton() {
    let errorMessage
    if (this.dokumente.length >= this._getMaxSizePerContext()) {
      errorMessage = html`
                <p class="upload-notice">
                    <i class="icon info circle"></i>
                    ${this.i18n.translate(`geschaeftsvorfall.${this.subKategorie}.detail.dokumente.maximum.erreicht`)}
                </p>
            `
    }
    return html`
      ${errorMessage}
      <div ?hidden="${!!errorMessage}">
        <isceco-file-input
          id="dokumente-upload-button"
          class="dokumente-upload-button"
          .fileInputService="${new VzavgFileInputService(`${this.url.split('file')[0] + this.subKategorie}/${this.objektId}/file`)}"
          label="${this.i18n.translate(`geschaeftsvorfall.${this.subKategorie}.detail.dokumente.hochladen`)}"
          accept="${this.validationConfig.allowedExtensions}"
          maxsize="${this.validationConfig.maxSize}"
          clear-event="${DocumentUpload.EVENT_KEYS.UPLOAD_COMPLETE}"
          @change="${e => this._onDokumentUploaded(e)}"
        ></isceco-file-input>
      </div>
    `
  }

  _getShortName(name) {
    const maxLength = 35;
    return name.length > maxLength ? name.substring(0, maxLength).concat('...') : name;
  }

  _getDeleteBtnId(name) {
    return 'btn-delete-'.concat(this._getShortName(name).split(' ').join('').replaceAll('.', ''))
  }

  _onDokumentUploaded(e) {
    if (e.detail.valid) {
      send(DocumentUpload.EVENT_KEYS.UPLOAD_COMPLETE)
      e.detail.size = this.dokumente.length +1
      send('changes',e, this)
      if (this.listHash) {
        navigate({to: this.listHash, id: this.gesuchId})
      } else {
        this.fetchData()
      }
    }
  }

  _onDeleteClick(index) {
    const fileName = this.dokumente[index].fileName;
    this.dokumentService.setRelativeBase(this.dokumentService.relativeBase.split('/file')[0])
    this.dokumentService.delete(`${this.subKategorie}/${this.objektId}/file`, fileName).then(_ => {
      this.fetchData()
    });
  }

  _getMaxSizePerContext() {
    if(this.subKategorie === 'pendenz') {
      return this.validationConfig.maxSizePendenz
    } else if (this.subKategorie === 'notiz') {
      return this.validationConfig.maxSizeNotiz
    } else {
      return this.validationConfig.maxSize
    }
  }
}
customElements.define('vzavg-document-upload', DocumentUpload)
