import {html, LitElement, css} from '@isceco/widget-library2/external/lit'
import {List} from '@isceco/widget-library2/basic-elements/List/List'

export default class YearFilter extends LitElement {

  static get styles() {
    return css`
      .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .year {
        margin-left: 1em;
        margin-right: 1em;
        font-size: 1.2em;
      }
    `
  }

  static get properties() {
    return {
      key: {type: String},
      identifier: {type: String},
      default: {type: Number},
      min: {type: Number},
      max: {type: Number},
    }
  }

  constructor() {
    super()
    this.value = null
    this.key = ''
    this.identifier = ''
    this.min = 0
    this.max = 9999
  }

  connectedCallback() {
    if (this.default) {
      this.value = this.default
    }
    super.connectedCallback();
    document.addEventListener(
      List.EVENT_KEYS.FILTER_CHANGED,
      this._changeFilter
    )
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener(
      List.EVENT_KEYS.FILTER_CHANGED,
      this._changeFilter
    )
  }

  render() {
    return html`
      <div class="wrapper">
        <isceco-button variant="secondary" icon="angle left" size="small" ?disabled="${this.value <= this.min}"
                       @click="${_ => this._backward()}">
        </isceco-button>
        <div class="year">${this.value}</div>
        <isceco-button variant="secondary" icon="angle right" size="small" ?disabled="${this.value >= this.max}"
                       @click="${_ => this._forward()}">
        </isceco-button>
      </div>
    `
  }

  _backward() {
    if (this.value > this.min) {
      this.value -= 1
      this._send()
      this.requestUpdate()
    }
  }

  _forward() {
    if (this.value < this.max) {
      this.value += 1
      this._send()
      this.requestUpdate()
    }
  }

  _changeFilter = e => {
    const {params, identifier} = e.detail
    if (!isEmpty(identifier) && identifier !== this.identifier) {
      return
    }
    this.value = typeof (params[this.key]) !== 'undefined' && !isEmpty(params[this.key])
      ? params[this.key]
      : this.default
    this.requestUpdate()
  }

  _send() {
    send(List.EVENT_KEYS.RELOAD_TRIGGERED, {key: this.key, value: this.value, identifier: this.identifier})
  }

}

customElements.define('vzavg-year-filter', YearFilter);
