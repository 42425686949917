import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../../WebComponent.js'
import StammdatenService from '../../../../services/StammdatenService.js'

export default class GesuchFilter extends WebComponent {

  constructor() {
    super()
    const stammdatenService = new StammdatenService();
    Promise.all([stammdatenService.getOrganisation(), stammdatenService.getEntscheidGesuch()])
      .then(([kantone, entscheidGesuch]) => {
        this.kantone = kantone.map(kanton => ({value: kanton.code, name: kanton.bezeichnung}))
        this.entscheidGesuch = entscheidGesuch.map(eg => ({value: eg.code, name: eg.bezeichnung}))
      })
      .then(_ => this.render())
  }

  get translationFile() {
    return './views/Suchen/Form/Filter/i18n.json'
  }

  get values() {
    return this._values
  }

  set values(val) {
    this._values = val
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      <div class="filter-wrapper">
        <isceco-dropdown
          label="${this.i18n.translate('suchen.betrieb.kanton')}"
          id="kanton"
          name="kanton"
          .items="${this.kantone}"
          .value="${this.values?.kanton ?? sessionStorage.getItem('userRechtCodeKanton')}"
        ></isceco-dropdown>
        <isceco-date-input
          label="${this.i18n.translate('suchen.gesuch.eingangsdatumVon')}"
          id="eingangsdatumVon"
          name="eingangsdatumVon"
          .value="${this.values?.eingangsdatumVon}"
          .max="${isEmpty(this.values?.eingangsdatumVon) ? '' : this.values?.eingangsdatumBis}"
          @change="${e => this._updateValue(e)}"
        >
        </isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.gesuch.eingangsdatumBis')}"
          id="eingangsdatumBis"
          name="eingangsdatumBis"
          .min="${isEmpty(this.values?.eingangsdatumBis) ? '' : this.values?.eingangsdatumVon}"
          .value="${this.values?.eingangsdatumBis}"
          @change="${e => this._updateValue(e)}"
        >
        </isceco-date-input>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.gesuch.gesuchsart')}"
          id="gesuchsart" name="gesuchsart"
          .value="${this.values?.gesuchsart}"
          .items="${[
            {name: this.i18n.translate('suchen.gesuch.erstgesuch'), value: 'erstgesuch'},
            {
              name: this.i18n.translate('suchen.gesuch.aenderungsgesuch'),
              value: 'aenderungsgesuch'
            }
          ]}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.gesuch.entscheid')}"
          id="entscheid" name="entscheid"
          .value="${this.values?.entscheid}"
          .items="${this.entscheidGesuch}"
        ></isceco-dropdown>
        <isceco-dropdown
          label="${this.i18n.translate('suchen.gesuch.bereich')}"
          id="bereich" name="bereich"
          .value="${this.values?.bereich}"
          .items="${[
            {name: this.i18n.translate('suchen.gesuch.kanton'), value: 'kanton'},
            {name: this.i18n.translate('suchen.gesuch.seco'), value: 'seco'}
          ]}"
        ></isceco-dropdown>
        <isceco-date-input
          label="${this.i18n.translate('suchen.gesuch.bestaetigungsdatumVon')}"
          id="bestaetigungsdatumVon"
          name="bestaetigungsdatumVon"
          .value="${this.values?.bestaetigungsdatumVon}"
          .max="${isEmpty(this.values?.bestaetigungsdatumVon) ? '' : this.values?.bestaetigungsdatumBis}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
        <isceco-date-input
          label="${this.i18n.translate('suchen.gesuch.bestaetigungsdatumBis')}"
          id="bestaetigungsdatumBis"
          name="bestaetigungsdatumBis"
          .min="${isEmpty(this.values?.bestaetigungsdatumBis) ? '' : this.values?.bestaetigungsdatumVon}"
          .value="${this.values?.bestaetigungsdatumBis}"
          @change="${e => this._updateValue(e)}"
        ></isceco-date-input>
      </div>
    `
  }

  _updateValue(event) {
    const key = event.target.id;
    if (key.includes('Von') || key.includes('Bis')) {
      if (this.values[key] !== event.detail.value) {
        this.values[key] = event.detail.value
        const date = new Date(event.detail.value)
        if (date?.getFullYear() > 1980) {
          this.reload()
        }
      }
    } else {
      if (this.values[key] !== event.detail.value) {
        this.values[key] = event.detail.value
      }
    }
  }

  get css() {
    return css`
      .filter-wrapper > * {
        min-width: 20em;
      }
    `
  }
}
customElements.define('vzavg-frontend-suche-gesuch-filter', GesuchFilter)
